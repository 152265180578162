body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App_header {
  color: #fff;
  background-color: #282c34;
  padding: 5px;
}

h1, h2, h3 {
  margin: 0;
  padding: 5px;
}

main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (min-width: 1024px) {
  h1, h2, h3 {
    padding: 15px;
  }
  main {
    flex-wrap: nowrap;
  }
}

.col {
  padding: 10px 15px;
}

.col_tag-list {
  flex: 1 1 320px;
  overflow: auto;
}

@media (min-width: 1024px) {
  .content {
    overflow: auto;
    height: calc(100vh - 100px);
  }

  .col_tag-list {
    max-width: 320px;
    position: -webkit-sticky;
    top: 0px;
    position: sticky;
  }
}

.intro_elem {
  margin: 20px;
}
.intro_icon {
  margin: auto;
  background: 3px solid green;
  padding: 20px;
}

.col_secret-list {
  flex: 1 1 1024px;
}

.loading_icon_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 50px;
}

.loading_icon {
  -webkit-animation: LoadingIconFrames infinite 2s linear;
          animation: LoadingIconFrames infinite 2s linear;
  height: 40px;
  width: 40px;
  border: solid 2px black;
  pointer-events: none;
}

@-webkit-keyframes LoadingIconFrames {
  0% {
    transform: rotate(0deg);
    background-color: green;
  }
  50% {
    transform: rotate(180deg);
    background-color:yellow;
  }
  100% {
    transform: rotate(360deg);
    background-color: green;
  }
}

@keyframes LoadingIconFrames {
  0% {
    transform: rotate(0deg);
    background-color: green;
  }
  50% {
    transform: rotate(180deg);
    background-color:yellow;
  }
  100% {
    transform: rotate(360deg);
    background-color: green;
  }
}

.clickable {
  cursor: pointer;
}

.item {
  padding: 8px 5px;
  margin: 8px 0;
  background-color: #cccccc;
}

.item.active {
  background-color: #666666;
  color: #ffffff;
}

.item:hover {
  background-color: #999999;
}

.addNewItem {
  display: flex;
  justify-content: flex-end;
}

.secret-list {
  width: 100%;
}

.item-prop {
  min-height:1em;
  display: flex;
  align-items: center;
}

.item-prop__functions {
  justify-content: flex-end;
}

.item-prop input {
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
}

button.icon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: block;
  width: 30px;
  height: 30px;
  margin-left: 15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
button.icon:focus, button.icon:active {
  outline: none;
}

.item-prop_label {
  flex: 0 1 8em;
  text-align: left;
}
.item-prop_value {
  flex: 1 1 100%;
  text-align: left;
  word-break: break-word;
}

.hide_on_mobile {
  display: none;
}

.rotate_180deg {
  transform: rotate(180deg);
}

@media (min-width: 1024px) {
  .hide_on_mobile {
    display: inline;
    display: initial;
  }
  .hide_on_desktop {
    display: none!important;
  }
  
  .item {
    display: flex;
    justify-content: space-between;
    margin: 3px 0;
  }
  .addNewItem {
    justify-content: flex-end;
  }
  .secret-list hr {
    display: none;
  }
  .item-prop {
    flex: 1 1 25%;
  }
  .item-prop__functions {
    flex: 1 1 7em;
  }
  
  .item-prop_label {
    display: none;
  }
  .first-item {
    position: relative;
    margin-top: 45px;
  }
  .first-item .item-prop_label {
    display: block;
    position: absolute;
    top: -34px;
    background: #cccccc;
    padding: 5px 3px 5px 5px;
    width: 7em;
    margin-left: -5px;
  }
  .first-item .item-prop_label:empty {
    display: none;
  }
}

